import { render, staticRenderFns } from "./element-assessment.vue?vue&type=template&id=6ec10433&"
import script from "./element-assessment.vue?vue&type=script&lang=ts&"
export * from "./element-assessment.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VContainer,VFlex,VLayout,VProgressCircular,VScaleTransition})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ec10433')) {
      api.createRecord('6ec10433', component.options)
    } else {
      api.reload('6ec10433', component.options)
    }
    module.hot.accept("./element-assessment.vue?vue&type=template&id=6ec10433&", function () {
      api.rerender('6ec10433', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/elements/element-assessment.vue"
export default component.exports