import { render, staticRenderFns } from "./element-carousel.vue?vue&type=template&id=266a5bde&"
import script from "./element-carousel.vue?vue&type=script&lang=ts&"
export * from "./element-carousel.vue?vue&type=script&lang=ts&"
import style0 from "./element-carousel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VCardActions,VFlex,VItem,VItemGroup,VLayout,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('266a5bde')) {
      api.createRecord('266a5bde', component.options)
    } else {
      api.reload('266a5bde', component.options)
    }
    module.hot.accept("./element-carousel.vue?vue&type=template&id=266a5bde&", function () {
      api.rerender('266a5bde', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/elements/element-carousel.vue"
export default component.exports