import { render, staticRenderFns } from "./comments-container.vue?vue&type=template&id=fc45546a&"
import script from "./comments-container.vue?vue&type=script&lang=ts&"
export * from "./comments-container.vue?vue&type=script&lang=ts&"
import style0 from "./comments-container.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout,VPagination,VProgressCircular,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fc45546a')) {
      api.createRecord('fc45546a', component.options)
    } else {
      api.reload('fc45546a', component.options)
    }
    module.hot.accept("./comments-container.vue?vue&type=template&id=fc45546a&", function () {
      api.rerender('fc45546a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/comments/comments-container.vue"
export default component.exports